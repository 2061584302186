
import { defineComponent } from "vue";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import ContentTable from "@/components/reusable/policy-information/house-insurance/ContentTable.vue";
import Generators from "@/components/reusable/policy-information/house-insurance/Generators.vue";
import "viewerjs/dist/viewer.css";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "house-building-policy-information-component",
  components: { ContentTable, Generators },
  props: {
    widgetClasses: String,
    policySummary: Object,
  },
  data() {
    return {
      imageURL: "",
    };
  },
  setup() {
    const { isEmptyArray, isObjectEmpty } = ResusableFunctions();

    return {
      isEmptyArray,
      isObjectEmpty,
    };
  },
  created() {
    // Policy images route
    this.imageURL = variables.POLICY_IMAGES_ROUTE;
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    downloadEndorsement(publicId, registrationNumber) {
      const submitButton1 = document.getElementById(publicId);

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.setResponseType("blob");
          ApiService.get(
            `${variables.POLICIES_ROUTE}/${publicId}/cover-document`
          )
            .then((response) => {
              submitButton1.removeAttribute("data-kt-indicator");
              const pdfFormat = "application/pdf";

              const mimeType =
                response.data.type == pdfFormat ? pdfFormat : "image/jpeg";
              const mimeTypeExtension =
                response.data.type == pdfFormat ? "pdf" : "jpeg";

              const blob = new Blob([response.data], {
                type: mimeType,
              });

              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = `${registrationNumber}-endorsement.${mimeTypeExtension}`;

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch(function(error) {
              console.log(error);
              variables.toastAlert(error.response.data.error, "error");
              submitButton1.removeAttribute("data-kt-indicator");
            });
        }
      }
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/blank.png";
    },
  },
});
